.page_header {
  font-weight: 800;
  text-align: center;
  line-height: normal;
  /* margin-bottom: 0px; */
}

.page_sub_header {
  font-weight: 100;
  /* max-width: 80%; */
  justify-content: center;
  text-align: center;
  line-height: normal;
  /* padding-bottom: 20px; */
}

.question_rating_container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* margin: 5px; */
  /* width: 80%; */
}

/* .question_rating_item {
  padding: 5px;
    margin: 10px;
}

.daily_page_sizing {
  height: 90vh;
}

.random_page_sizing {
  height: 120vh;
} */

@media (max-width: 800px) {
  .daily_page_sizing {
    padding-top:50px;
  }

  .random_page_sizing {
    padding-top:50px;
  }
}
