.button_container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  margin: 5px;
  /* padding: 5px; */

  padding-left: 10px;
  padding-right: 10px;
  width: auto;
  transition: .2s;
  height: auto;
  min-height: 50px;
  /* min-width: 100px; */
  display: inline-flex;
  align-items: center;
  max-width: 200px;
}


.button_container:hover {
    /* background-color:rgb(158, 158, 158) ; */
    transition: .3s;
    box-shadow: 100 10 10 10 black;
  }
  
  .button_inner_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }