@keyframes correctFlash {
  0% {
    background-color: #ddecff00;
    transform: scale(0); /* Start smaller */
    opacity: 0; /* Fully transparent */
  }
  50% {
    background-color: #007bff2c;
    transform: scale(1.05); /* Grow larger */
    opacity: 1; /* Fully visible */
  }
  100% {
    background-color: #ddebff00;
    transform: scale(1); /* Back to normal size */
    opacity: 1; /* Fully visible */
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.correct_animation {
  animation:
    correctFlash 1s ease 0.5s,
    /* CorrectFlash animation with delay */ pulse 0.5s ease 1s; /* Pulse animation with delay */

  transform: scale(0); /* Initially smaller */
}

.correct_animation_visible {
  opacity: 1; /* Make visible */
  transform: scale(1); /* Reset to normal size */
}
