body {
  margin: 0;
  font-family: "Helvetica", "Helvetica Neue", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 100;
}

h4 {
  font-size: 1rem;
}
p {
  font-size: 1rem;
}

@media (max-width: 800px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: 100;
  }

  h4 {
    font-size: 1rem;
  }

  p {
    font-size: 0.75rem;
  }
}
