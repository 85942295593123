.bbacklight {
  background-image: "/media/trivial_owl_blue.png";
}

.bbacklight:after {
  content: "";
  /* height: 100%;
  width: 100%; */
  width: "80vw";
  /* max-width: "1000px"; */
  height: "50vw";
  /* max-height: "625px"; */
  top: 0;
  position: absolute;
  background: inherit;
  filter: blur(20px);
  z-index: -1;
  background-size: cover;
  transform: scale(1);
  align-self: "center";
}


.backlight {
    /* background-image: "/media/trivial_owl_blue.png"; */
    filter: blur(20px) brightness(50%) hue-rotate(45deg);
    /* position: absolute; */
    /* background: inherit; */
   
    z-index: -1;
    transform: scale(1.05);
  
    /* top: 0;
    position: absolute; */
  }
  
  
  .original {
    content: "";
    /* height: 100%;
      width: 100%; */
    top: 0;
    /* position: absolute; */
    /* background: inherit; */
    /* filter: blur(100px); */
    z-index: 100;
    /* background-size: cover; */
    /* transform: scale(3);  */
    z-index: 1;
  }
  