.question_container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* max-width: 600px; */
  /* margin-left: 5%;
    margin-right: 5%; */
  /* width: 80%;
    min-width: 80vw; */
  text-align: center;
  /* transition: 1s; */
  height: auto;
  /* width: inherit; */
  /* padding: 20px; */
}

.question {
  /* margin: 5px; */
  /* height: 200px; */
  /* font-size: xx-large; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding: 50px; 
  /* min-height: 20vh; */

  /* margin: 0 auto; */
  /* min-height: 200px; */
  /* width: 60vw; */
  /* padding-left: 10%;
    padding-right: 10%; */
}

@media (max-width: 800px) {
  .question {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 0px; 
  }
}


.question_section {
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  margin: 20px;
}

.multiple_choice_buttons_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  margin: 5px;
}

.answer_toggle_section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  /* min-height: 150px; */
  margin: 0 auto;
  /* padding-top: 20px; */
}

.filter_menu_toggle_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  min-height: 150px;
  margin: 0 auto;
  padding-top: 60px;
}

.question_rating_container {
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.question_rating_item {
  padding: 5px;
  margin: 10px;
}

.answer_card {
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  font-size: x-large;
}

.answer_card:hover {
  background-color: rgba(57, 255, 39, 0.205);
  transition: 0.2s;
}

.input_box {
  font-size: x-large;
}

.feedback_wrapper {
  border: 5px; /* border: 1px solid black; */
  /* background-color: #1871c1; */
  border-radius: 20px;
  padding: 25px;
}

.question_answer_wrapper {
  border: 5px;
  /* border: 1px solid black; */
  /* background-color: #1a3044; */
  border-radius: 20px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 0 auto;
  width: 100%;
  /* min-height: 50vh; */
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}

@media (max-width: 800px) {
  .question_answer_wrapper {
    /* border: 5px; */
    /* border: 1px solid black; */
    /* background-color: #1871c1; */
    border-radius: 20px;
    padding: 25px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0 auto;
    width: 100%;
    min-height: 30vh;
  }
}

.segment_display_content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* 
    margin-top: 40px;
    margin-bottom: -40px; */
  /* align-items: center; */
  /* margin: 0 auto; */
}

.pill_placer {
  position: absolute;
  top: 40px;
  right: 5%;
  margin: 0;
  padding: 10px;
}

.normal_no_animation {
  background-color: inherit;
  border: 0px;
  transition: 1s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes flashRed {
  0% {
    background-color: inherit;
  } /* Light red */
  20% {
    background-color: #ff000082;
  } /* Full red */
  100% {
    background-color: inherit;
  } /* Back to light red */
}

.incorrect_animation {
  animation: shake 0.25s ease, flashRed 0.5s ease;
}

@keyframes correctFlash {
  0% {
    background-color: #ddffdd00;
  } /* Light green */
  50% {
    background-color: #00ff0021;
  } /* Full green */
  100% {
    background-color: #ddffdd00;
  } /* Back to light green */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.correct_animation {
  animation: correctFlash 0.5s ease, pulse 0.5s ease;
  /* border: 1px solid #00ab00;  */
}

@keyframes enterShake {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translatey(5px);
  }
  /* 50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  } */
  100% {
    transform: translateX(0);
  }
}

@keyframes flashBlue {
  0% {
    background-color: #ffffff00;
  } /* Light red */
  20% {
    background-color: #0011ff2c;
  } /* Full red */
  100% {
    background-color: #ffdddd00;
  } /* Back to light red */
}

.enter_pulse {
  animation: enterShake 0.1s ease, flashBlue 0.2s ease;
}


@keyframes closeShake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes flashYellow {
  0% {
    background-color: inherit;
  } /* Light red */
  20% {
    background-color: #eaff0086;
  } /* Full red */
  100% {
    background-color: inherit;
  } /* Back to light red */
}

.close_animation {
  animation: closeShake .25s ease, flashYellow .5s ease;
}







.backlight {
  /* background-image: "/media/trivial_owl_blue.png"; */
  filter: blur(20px) brightness(50%) hue-rotate(45deg);
  /* position: absolute; */
  /* background: inherit; */
 
  z-index: -1;
  transform: scale(1.05);

  /* top: 0;
  position: absolute; */
}


.original {
  content: "";
  /* height: 100%;
    width: 100%; */
  top: 0;
  /* position: absolute; */
  /* background: inherit; */
  /* filter: blur(100px); */
  z-index: 100;
  /* background-size: cover; */
  /* transform: scale(3);  */
  z-index: 1;
}
