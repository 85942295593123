.fade-message {
    opacity: 1; /* Initially visible */
    transition: opacity 1s ease-in-out; /* Transition effect */
  }
  
  .hidden {
    opacity: 0; /* Fade out */
    transition: opacity 1s ease-in-out; /* Transition effect */
  }
  
  .visible {
    opacity: 1; /* Fully visible */
  }
  